/**
 * @file: BillingOrderAuthorType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum BillingOrderAuthorType {
  Openapi = 'openapi',
  SalesAgent = 'sales_agent',
  Sales = 'sales',
}

export const BillingOrderAuthorTypeValueSet = new Set([
  BillingOrderAuthorType.Openapi,
  BillingOrderAuthorType.SalesAgent,
  BillingOrderAuthorType.Sales,
]);
