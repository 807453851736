/**
 * @file: ServiceFeatureName.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceFeatureName {
  OrderReception = 'order-reception',
  PreInspection = 'pre-inspection',
  Inspection = 'inspection',
  Construction = 'construction',
  DeliveryCheck = 'delivery-check',
  VehicleReport = 'vehicle-report',
  CustomerReview = 'customer-review',
  WorkbenchWebEdition = 'workbench-web-edition',
  MomentShare = 'moment-share',
  DailyReport = 'daily-report',
  HubDisplay = 'hub-display',
  DataDashboard = 'data-dashboard',
  SamplingCheck = 'sampling-check',
  Quotation = 'quotation',
  DataReport = 'data-report',
  ProductReward = 'product-reward',
  BusinessIntelligence = 'business-intelligence',
  PreciseMarketing = 'precise-marketing',
}

export const ServiceFeatureNameValueSet = new Set([
  ServiceFeatureName.OrderReception,
  ServiceFeatureName.PreInspection,
  ServiceFeatureName.Inspection,
  ServiceFeatureName.Construction,
  ServiceFeatureName.DeliveryCheck,
  ServiceFeatureName.VehicleReport,
  ServiceFeatureName.CustomerReview,
  ServiceFeatureName.WorkbenchWebEdition,
  ServiceFeatureName.MomentShare,
  ServiceFeatureName.DailyReport,
  ServiceFeatureName.HubDisplay,
  ServiceFeatureName.DataDashboard,
  ServiceFeatureName.SamplingCheck,
  ServiceFeatureName.Quotation,
  ServiceFeatureName.DataReport,
  ServiceFeatureName.ProductReward,
  ServiceFeatureName.BusinessIntelligence,
  ServiceFeatureName.PreciseMarketing,
]);
