/**
 * @file: AfterSalesServiceLevelLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AfterSalesServiceLevel } from 'model';
import React, { CSSProperties } from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: AfterSalesServiceLevel | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  className?: string;
  style?: CSSProperties;
  color?: ColorValue;
}

export const AfterSalesServiceLevelLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  const labelColor =
    value && LabelColors.AfterSalesServiceLevel?.[value]
      ? LabelColors.AfterSalesServiceLevel[value]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`after_sales_service_level.${value}`} />
    </Badge>
  );
});
