/**
 * @file: util.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
import { ServiceEdition, ServiceFeatureName } from 'model';

type ServiceEditionFeatureFlagKey = Extract<
  keyof ServiceEdition,
  | 'isOrderReceptionEnabled'
  | 'isPreInspectionEnabled'
  | 'isInspectionEnabled'
  | 'isConstructionEnabled'
  | 'isDeliveryCheckEnabled'
  | 'isSamplingCheckEnabled'
  | 'isQuotationEnabled'
  | 'isVehicleReportEnabled'
  | 'isCustomerReviewEnabled'
  | 'isMomentShareEnabled'
  | 'isDailyReportEnabled'
  | 'isWorkbenchWebVersionEnabled'
  | 'isDataReportEnabled'
  | 'isProductRewardEnabled'
  | 'isTvEditionEnabled'
  | 'isDataV1Enabled'
  | 'isNewBiEnabled'
  | 'isNewMarketingEnabled'
>;

export const kFeatureNameToServiceEditionKeyMap: {
  [p in ServiceFeatureName]: ServiceEditionFeatureFlagKey;
} = {
  [ServiceFeatureName.OrderReception]: 'isOrderReceptionEnabled',
  [ServiceFeatureName.PreInspection]: 'isPreInspectionEnabled',
  [ServiceFeatureName.Inspection]: 'isInspectionEnabled',
  [ServiceFeatureName.Construction]: 'isConstructionEnabled',
  [ServiceFeatureName.DeliveryCheck]: 'isDeliveryCheckEnabled',
  [ServiceFeatureName.SamplingCheck]: 'isSamplingCheckEnabled',
  [ServiceFeatureName.Quotation]: 'isQuotationEnabled',
  [ServiceFeatureName.VehicleReport]: 'isVehicleReportEnabled',
  [ServiceFeatureName.CustomerReview]: 'isCustomerReviewEnabled',
  [ServiceFeatureName.MomentShare]: 'isMomentShareEnabled',
  [ServiceFeatureName.DailyReport]: 'isDailyReportEnabled',
  [ServiceFeatureName.WorkbenchWebEdition]: 'isWorkbenchWebVersionEnabled',
  [ServiceFeatureName.DataReport]: 'isDataReportEnabled',
  [ServiceFeatureName.ProductReward]: 'isProductRewardEnabled',
  [ServiceFeatureName.HubDisplay]: 'isTvEditionEnabled',
  [ServiceFeatureName.DataDashboard]: 'isDataV1Enabled',
  [ServiceFeatureName.BusinessIntelligence]: 'isNewBiEnabled',
  [ServiceFeatureName.PreciseMarketing]: 'isNewMarketingEnabled',
};
