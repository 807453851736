/**
 * @file: BillingOrderStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum BillingOrderStatus {
  Pending = 'pending',
  Paid = 'paid',
  Finished = 'finished',
  Canceled = 'canceled',
  Closed = 'closed',
}

export const BillingOrderStatusValueSet = new Set([
  BillingOrderStatus.Pending,
  BillingOrderStatus.Paid,
  BillingOrderStatus.Finished,
  BillingOrderStatus.Canceled,
  BillingOrderStatus.Closed,
]);
