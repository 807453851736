/**
 * @file: AfterSalesServiceLevelOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AfterSalesServiceLevel, Option } from 'model';

export const AfterSalesServiceLevelOptions: Array<
  Option<AfterSalesServiceLevel>
> = [
  {
    value: AfterSalesServiceLevel.Basic,
    label: '@string/after_sales_service_level.basic',
  },
  {
    value: AfterSalesServiceLevel.Standard,
    label: '@string/after_sales_service_level.standard',
  },
  {
    value: AfterSalesServiceLevel.Premium,
    label: '@string/after_sales_service_level.premium',
  },
];

export const AfterSalesServiceLevelOptionsWithDefault: Array<
  Option<AfterSalesServiceLevel | null>
> = [
  { value: null, label: 'after_sales_service_level.__default__' },
  {
    value: AfterSalesServiceLevel.Basic,
    label: 'after_sales_service_level.basic',
  },
  {
    value: AfterSalesServiceLevel.Standard,
    label: 'after_sales_service_level.standard',
  },
  {
    value: AfterSalesServiceLevel.Premium,
    label: 'after_sales_service_level.premium',
  },
];
