/**
 * @file: AfterSalesServiceLevel.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum AfterSalesServiceLevel {
  Basic = 'basic',
  Standard = 'standard',
  Premium = 'premium',
}

export const AfterSalesServiceLevelValueSet = new Set([
  AfterSalesServiceLevel.Basic,
  AfterSalesServiceLevel.Standard,
  AfterSalesServiceLevel.Premium,
]);
