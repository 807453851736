/**
 * @file: ServiceFeatureNameOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceFeatureName } from 'model';

export const ServiceFeatureNameOptions: Array<Option<ServiceFeatureName>> = [
  {
    value: ServiceFeatureName.OrderReception,
    label: '@string/service_feature_name.order-reception',
  },
  {
    value: ServiceFeatureName.PreInspection,
    label: '@string/service_feature_name.pre-inspection',
  },
  {
    value: ServiceFeatureName.Inspection,
    label: '@string/service_feature_name.inspection',
  },
  {
    value: ServiceFeatureName.Construction,
    label: '@string/service_feature_name.construction',
  },
  {
    value: ServiceFeatureName.DeliveryCheck,
    label: '@string/service_feature_name.delivery-check',
  },
  {
    value: ServiceFeatureName.SamplingCheck,
    label: '@string/service_feature_name.sampling-check',
  },
  {
    value: ServiceFeatureName.Quotation,
    label: '@string/service_feature_name.quotation',
  },
  {
    value: ServiceFeatureName.VehicleReport,
    label: '@string/service_feature_name.vehicle-report',
  },
  {
    value: ServiceFeatureName.CustomerReview,
    label: '@string/service_feature_name.customer-review',
  },
  {
    value: ServiceFeatureName.MomentShare,
    label: '@string/service_feature_name.moment-share',
  },
  {
    value: ServiceFeatureName.DailyReport,
    label: '@string/service_feature_name.daily-report',
  },
  {
    value: ServiceFeatureName.HubDisplay,
    label: '@string/service_feature_name.hub-display',
  },
  {
    value: ServiceFeatureName.WorkbenchWebEdition,
    label: '@string/service_feature_name.workbench-web-edition',
  },
  {
    value: ServiceFeatureName.DataDashboard,
    label: '@string/service_feature_name.data-dashboard',
  },
  {
    value: ServiceFeatureName.DataReport,
    label: '@string/service_feature_name.data-report',
  },
  {
    value: ServiceFeatureName.ProductReward,
    label: '@string/service_feature_name.product-reward',
  },
  {
    value: ServiceFeatureName.BusinessIntelligence,
    label: '@string/service_feature_name.business-intelligence',
  },
  {
    value: ServiceFeatureName.PreciseMarketing,
    label: '@string/service_feature_name.precise-marketing',
  },
];

export const ServiceFeatureNameOptionsWithDefault: Array<
  Option<ServiceFeatureName | null>
> = [
  { value: null, label: 'service_feature_name.__default__' },
  {
    value: ServiceFeatureName.OrderReception,
    label: 'service_feature_name.order-reception',
  },
  {
    value: ServiceFeatureName.PreInspection,
    label: 'service_feature_name.pre-inspection',
  },
  {
    value: ServiceFeatureName.Inspection,
    label: 'service_feature_name.inspection',
  },
  {
    value: ServiceFeatureName.Construction,
    label: 'service_feature_name.construction',
  },
  {
    value: ServiceFeatureName.DeliveryCheck,
    label: 'service_feature_name.delivery-check',
  },
  {
    value: ServiceFeatureName.SamplingCheck,
    label: 'service_feature_name.sampling-check',
  },
  {
    value: ServiceFeatureName.Quotation,
    label: 'service_feature_name.quotation',
  },
  {
    value: ServiceFeatureName.VehicleReport,
    label: 'service_feature_name.vehicle-report',
  },
  {
    value: ServiceFeatureName.CustomerReview,
    label: 'service_feature_name.customer-review',
  },
  {
    value: ServiceFeatureName.MomentShare,
    label: 'service_feature_name.moment-share',
  },
  {
    value: ServiceFeatureName.DailyReport,
    label: 'service_feature_name.daily-report',
  },
  {
    value: ServiceFeatureName.HubDisplay,
    label: 'service_feature_name.hub-display',
  },
  {
    value: ServiceFeatureName.WorkbenchWebEdition,
    label: 'service_feature_name.workbench-web-edition',
  },
  {
    value: ServiceFeatureName.DataDashboard,
    label: 'service_feature_name.data-dashboard',
  },
  {
    value: ServiceFeatureName.DataReport,
    label: 'service_feature_name.data-report',
  },
  {
    value: ServiceFeatureName.ProductReward,
    label: 'service_feature_name.product-reward',
  },
  {
    value: ServiceFeatureName.BusinessIntelligence,
    label: 'service_feature_name.business-intelligence',
  },
  {
    value: ServiceFeatureName.PreciseMarketing,
    label: 'service_feature_name.precise-marketing',
  },
];
