/**
 * @file: BillingOrderType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum BillingOrderType {
  New = 'new',
  Upgrade = 'upgrade',
  Renew = 'renew',
}

export const BillingOrderTypeValueSet = new Set([
  BillingOrderType.New,
  BillingOrderType.Upgrade,
  BillingOrderType.Renew,
]);
