/**
 * @file: SystemService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { OrderTag, ServiceVendor } from 'model';

export class SystemService {
  constructor(private readonly api: APIService) {}

  async getSystemConfig(): Promise<Record<string, any>> {
    return await this.api.get().url('/system/config').future();
  }

  async getSystemConfigItem<T = any>(key: string): Promise<T | undefined> {
    return await this.api.get().url('/system/config', { key }).future();
  }

  async setSystemConfig<T>(key: string, value: T): Promise<void> {
    return void (await this.api
      .put()
      .url('/system/config')
      .data({ key, value })
      .future());
  }

  async getPredefinedOrderTags(): Promise<OrderTag[]> {
    return await this.api.get().url('/system/predefined-order-tags').future();
  }

  async getOtcLoginCode(userId: string | number): Promise<string> {
    return await this.api
      .post()
      .url('/system/get-otc-login-code')
      .data({ userId })
      .future();
  }

  async getServiceVendors(): Promise<ServiceVendor[]> {
    return await this.api.get().url('/system/service-vendors').future();
  }
}
