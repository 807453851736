import { kFeatureNameToServiceEditionKeyMap } from 'app/settings/service-edition/util';
import { AclObjectList, ServiceEdition, ServiceEditionListFilter } from 'model';
import {
  AfterSalesServiceLevelOptions,
  ServiceEditionTypeOptions,
  ServiceEditionVariantTypeOptions,
} from 'model/EnumOptions';
import { Translate } from 'react-localize-redux';
import { systemService } from 'services';
import {
  AfterSalesServiceLevelLabel,
  Badge,
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  ServiceEditionTypeLabel,
  ServiceEditionVariantTypeLabel,
} from 'shared/components';
import { FeatureTable } from 'shared/components/FeatureTable';
import { formatDate } from 'utils';
import { serviceEditionActions } from '../duck/actions';

const kAllowUpdateCoreProps = false;

interface Props
  extends EntityListProps<ServiceEdition, ServiceEditionListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ServiceEdition,
  ServiceEditionListFilter,
  number,
  Props
>();

export const ServiceEditionList = componentClassBuilder
  .i18nPrefix('service_edition')
  .accessRights({
    full: AclObjectList.ServiceEditionFullAccess,
    readonly: AclObjectList.ServiceEditionReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.service_editions" /> },
  ])
  .entities(state => state.settings.serviceEditions)
  .actions(serviceEditionActions)
  .editor(builder => {
    builder
      .select({
        prop: 'type',
        options: ServiceEditionTypeOptions,
        label: 'service_edition.editor.label.type',
        placeholder: 'service_edition.editor.placeholder.type',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .select({
        prop: 'variant',
        options: ServiceEditionVariantTypeOptions,
        label: 'service_edition.editor.label.variant',
        placeholder: 'service_edition.editor.placeholder.variant',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .checkbox({
        prop: 'isTrialEdition',
        label: 'service_edition.editor.label.is_trial_edition',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .text({
        prop: 'trialDurationInDays',
        label: 'service_edition.editor.label.trial_duration_in_days',
        disabled(entity) {
          return (
            (!kAllowUpdateCoreProps && entity.id != null) ||
            !entity.isTrialEdition
          );
        },
      })
      .text({
        type: 'number',
        prop: 'userAccountLimit',
        label: 'service_edition.editor.label.user_account_limit',
        placeholder: 'service_edition.editor.placeholder.user_account_limit',
        helpText: 'service_edition.editor.help_text.user_account_limit',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .select({
        prop: 'afterSalesServiceLevel',
        options: AfterSalesServiceLevelOptions,
        label: 'service_edition.editor.label.after_sales_service_level',
        placeholder:
          'service_edition.editor.placeholder.after_sales_service_level',
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
      })
      .reactSelect({
        prop: 'vendorId',
        label: 'service_edition.editor.label.vendor_id',
        placeholder: 'service_edition.editor.label.vendor_id',
        valueProp: 'vendorId',
        labelProp: 'name',
        async: true,
        stateId: 'service_vendors',
        values: [],
        defaultValues: true,
        disabled(entity) {
          return !kAllowUpdateCoreProps && entity.id != null;
        },
        onLoadValues: async _keyword => {
          return await systemService.getServiceVendors();
        },
      })
      .custom({
        label: 'service_edition.editor.label.feature_set',
        render: (props: Props, entity) => {
          return (
            <FeatureTable
              editable={true}
              numCols={6}
              isFeatureEnabled={x =>
                entity[kFeatureNameToServiceEditionKeyMap[x]] ?? false
              }
              onToggleFeature={(name, enabled) => {
                if (props.entities.itemBeingCreated != null) {
                  props.dispatch(
                    props.actions.itemBeingCreatedChanged!({
                      [kFeatureNameToServiceEditionKeyMap[name]]: enabled,
                    }),
                  );
                } else if (props.entities.itemBeingUpdated != null) {
                  props.dispatch(
                    props.actions.itemBeingUpdatedChanged!({
                      [kFeatureNameToServiceEditionKeyMap[name]]: enabled,
                    }),
                  );
                }
              }}
            />
          );
        },
      })
      .text({
        type: 'number',
        prop: 'sortOrder',
        label: 'service_edition.editor.label.sort_order',
        placeholder: 'service_edition.editor.placeholder.sort_order',
      })
      .textArea({
        prop: 'remark',
        label: 'service_edition.editor.label.remark',
        placeholder: 'service_edition.editor.placeholder.remark',
      });

    return builder;
  })
  .addActionButtons(['edit', 'remove'])
  .columns([
    {
      prop: 'name',
      width: 250,
      text: 'col.name',
      render: ({ name, sku, isTrialEdition, trialDurationInDays }) => {
        return (
          <div>
            <div>
              <strong style={{ fontSize: '1.15rem' }}>{name}</strong>
            </div>
            <div
              style={{
                fontSize: '0.9rem',
                fontWeight: 500,
                color: '#888',
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
              }}
            >
              {sku}
            </div>
            {isTrialEdition ? (
              <div style={{ marginTop: '0.35rem' }}>
                <Badge
                  color="warning"
                  style={{
                    border: '1px solid #ffb822',
                    backgroundColor: 'rgb(255 184 34 / 13%)',
                  }}
                >
                  <Translate id="service_edition.trial_edition" />
                </Badge>
                <Badge
                  color="accent"
                  style={{
                    marginLeft: '0.35rem',
                    border: '1px solid #00c5dc',
                    backgroundColor: 'rgb(0 197 220 / 13%)',
                    color: '#000',
                  }}
                >
                  <Translate
                    id="service_edition.trial_duration_in_days"
                    data={{ days: trialDurationInDays }}
                  />
                </Badge>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      prop: 'type',
      width: 80,
      align: 'center',
      text: 'service_edition.col.type',
      render: ({ type }) => <ServiceEditionTypeLabel value={type} />,
    },
    {
      prop: 'variant',
      width: 80,
      align: 'center',
      text: 'service_edition.col.variant',
      render: ({ variant }) => (
        <ServiceEditionVariantTypeLabel value={variant} />
      ),
    },
    {
      prop: 'sku',
      width: 120,
      hidden: true,
      text: 'service_edition.col.sku',
      render: ({ sku }) => (
        <span
          style={{
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
          }}
        >
          {sku}
        </span>
      ),
    },
    {
      prop: 'afterSalesServiceLevel',
      width: 100,
      align: 'center',
      text: 'service_edition.col.after_sales_service_level',
      render: ({ afterSalesServiceLevel }) => (
        <AfterSalesServiceLevelLabel value={afterSalesServiceLevel} />
      ),
    },
    {
      prop: 'isTvEditionEnabled',
      width: 340,
      text: 'service_edition.col.feature_set',
      style: { overflowX: 'auto', minWidth: 340, width: 'auto' },
      render: (serviceEdition: ServiceEdition) => {
        return (
          <FeatureTable
            style={{ width: undefined }}
            numCols={6}
            isFeatureEnabled={x =>
              serviceEdition[kFeatureNameToServiceEditionKeyMap[x]]
            }
          />
        );
      },
    },
    {
      prop: 'userAccountLimit',
      width: 60,
      text: 'service_edition.col.user_account_limit',
      align: 'center',
      render: (serviceEdition: ServiceEdition) => {
        return serviceEdition.userAccountLimit > 0
          ? `${serviceEdition.userAccountLimit}`
          : getString('service_edition.seat_count.unlimited');
      },
    },
    {
      prop: 'isTrailEdition',
      width: 40,
      align: 'center',
      hidden: true,
      text: 'service_edition.col.is_trial_edition',
      render: ({ isTrialEdition }) => <Checkmark value={isTrialEdition} />,
    },
    {
      prop: 'createdAt',
      width: 80,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatDate(createdAt),
    },
  ])
  .toolbarItems(builder => {
    builder
      .select({
        prop: 'type',
        //label: 'service_edition.editor.label.type',
        placeholder: 'service_edition.editor.label.type',
        values: ServiceEditionTypeOptions,
        width: 200,
        clearable: true,
        array: true,
        onGetOptionLabel(option) {
          return getString(option.label);
        },
      })
      .select({
        prop: 'variant',
        //label: 'service_edition.editor.label.variant',
        placeholder: 'service_edition.editor.label.variant',
        values: ServiceEditionVariantTypeOptions,
        width: 200,
        clearable: true,
        array: true,
        onGetOptionLabel(option) {
          return getString(option.label);
        },
      })
      .select({
        prop: 'vendorId',
        //label: 'service_edition.editor.label.vendor_id',
        placeholder: 'service_edition.editor.label.vendor_id',
        valueProp: 'vendorId',
        labelProp: 'name',
        async: true,
        stateId: 'service_vendors',
        values: [],
        defaultValues: true,
        clearable: true,
        width: 250,
        onLoadValues: async _keyword => {
          return await systemService.getServiceVendors();
        },
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(serviceEditionActions.invalidate(true));
        },
      });
  })
  .onAdd(entity => {
    entity.isTrialEdition = false;
    entity.isOrderReceptionEnabled = true;
    entity.isPreInspectionEnabled = true;
    entity.isInspectionEnabled = true;
    entity.isConstructionEnabled = true;
    entity.isDeliveryCheckEnabled = true;
    entity.isSamplingCheckEnabled = false;
    entity.isQuotationEnabled = false;
    entity.isVehicleReportEnabled = true;
    entity.isCustomerReviewEnabled = true;
    entity.isMomentShareEnabled = false;
    entity.isDailyReportEnabled = false;
    entity.isWorkbenchWebVersionEnabled = true;
    entity.isDataReportEnabled = false;
    entity.isProductRewardEnabled = false;
    entity.isTvEditionEnabled = false;
    entity.isDataV1Enabled = false;
    entity.isNewBiEnabled = false;
    entity.isNewMarketingEnabled = false;
  })
  .validate(entity => {
    let msg = '';

    if (!entity.type) {
      msg = 'type_required';
    } else if (!entity.variant) {
      msg = 'variant_required';
    } else if (entity.userAccountLimit == null) {
      msg = 'user_account_limit_required';
    } else if (entity.userAccountLimit < 0) {
      msg = 'invalid_user_account_limit';
    }

    if (entity.isTrialEdition) {
      const trialDurationInDays = entity.trialDurationInDays
        ? Number(entity.trialDurationInDays)
        : entity.trialDurationInDays;
      if (!trialDurationInDays) {
        msg = 'trial_duration_in_days_required';
      } else if (trialDurationInDays < 1 || trialDurationInDays > 180) {
        msg = 'invalid_trial_duration_in_days';
      }
    }

    if (msg) {
      throw new Error(getString(`service_edition.editor.error.${msg}`));
    }
  })
  .getClass();
