import classNames from 'classnames';
import { SortDirection, SortInfo } from 'lib';
import React, {
  ChangeEvent,
  Component,
  CSSProperties,
  MouseEvent,
  PureComponent,
  ReactNode,
} from 'react';
import { Translate } from 'react-localize-redux';
import { Block, Pager } from 'shared/metronic/components';
import { Alert } from 'shared/metronic/components/Alert';

type HiddenPredict = () => boolean;
type ColTextType = ReactNode;
type ColTextFn = () => ColTextType;

type ColAlign = 'left' | 'center' | 'right';

const noop = () => {
  /* noop */
};

export interface EntityNode<T extends object> {
  entity: T;
  selected: boolean;
  expanded: boolean;
  children?: Array<EntityNode<T>>;
}

export interface Column<T extends object> {
  prop: string;
  name?: string;
  text: ColTextType | ColTextFn;
  width: number;
  cls?: string | string[] | { [key: string]: any };
  style?: CSSProperties;
  align?: ColAlign;
  hidden?: boolean | HiddenPredict;
  sortable?: boolean;
  sortProperty?: string;
  defaultSortDir?: SortDirection;
  emptyPlaceholder?: ReactNode | ((item: T, extra?: any) => ReactNode);
  render?: (
    item: T,
    extra: any,
    index: number,
    data: T[],
  ) => string | number | ReactNode;
  editor?: (
    item: T | Partial<T>,
    extra: any,
    index: number,
    data: T[],
  ) => string | ReactNode;
}

export type RecordInfoRenderer = (
  start: number,
  end: number,
  total: number,
) => string | ReactNode;

export type DataKeyType = number | string;

interface Props<T extends object, TKey extends DataKeyType = number> {
  className?: string;
  tableStyle?: CSSProperties;
  columns: Array<Column<T>>;
  cellCls?: string | string[] | { [key: string]: any };
  idProp: keyof T;
  selModel?: 'check' | 'row' | undefined | null | 'none';
  data: T[] | null | undefined;
  sorts?: SortInfo[];
  isLoading?: boolean;
  error?: Error | null | undefined;
  offset?: number;
  limit?: number;
  total?: number;
  maxPagerItems?: number;
  selection?: TKey[] | null;
  minHeight?: number;
  checkColWidth?: number;
  itemBeingCreated?: Partial<T> | null;
  itemBeingUpdated?: T | null;
  itemsBeingHighlighted?: T[] | null;
  rowClickable?: boolean;
  emptyPlaceholder?: ReactNode | ((extra?: any) => ReactNode);
  emptyCellPlaceholder?: ReactNode | ((item: T, extra?: any) => ReactNode);

  // control list groups
  groupListKey?: any;
  groupBy?: keyof T | ((item: T) => any);
  collapseGroupByDefault?: boolean;
  expandedGroups?: Set<any>;
  collapsedGroups?: Set<any>;
  onFormatGroupHeader?: (
    group: DataListGroup<T>,
    key?: any,
  ) => string | ReactNode;
  onListGroupExpand?: (value: any, key?: any) => void;
  onListGroupCollapse?: (value: any, key?: any) => void;
  onRenderListGroupActions?: (group: DataListGroup<T>, key?: any) => ReactNode;

  // control item details
  enableItemDetails?: boolean;
  expandItemDetailByDefault?: boolean;
  expandedItemDetailIds?: Set<any>;
  collapsedItemDetailIds?: Set<any>;
  onItemDetailExpand?: (item: T) => void;
  onItemDetailCollapse?: (item: T) => void;
  onRenderItemDetail?: (item: T) => ReactNode;

  renderRecordInfo?: RecordInfoRenderer;
  shouldHideColumn?: (name: string) => boolean;
  onGetExtraInfo?: () => any;
  onToggleAllSelection?: () => void;
  onItemSelect?: (item: T, selected: boolean) => void;
  onOffsetChange?: (offset: number) => void;
  onLimitChange?: (limit: number) => void;
  onToggleSort?: (
    property: string,
    defaultDir: SortDirection | undefined,
  ) => void;
  onRemoveSort?: (property: string) => void;
  onSetSort?: (property: string, dir: SortDirection) => void;
  onRowClick?: (item: T, extra: any) => void;
  treeBuilder?: (items: T[], extra: any) => Array<EntityNode<T>>;
  onListItemNodeExpand?: (item: T) => void;
  onListItemNodeCollapse?: (item: T) => void;
}

let defaultRecordInfoRenderer: RecordInfoRenderer = (
  start: number,
  end: number,
  total: number,
) => <Translate id="pager.record_info" data={{ start, end, total }} />;

export function replaceDefaultRecordInfoRenderer(renderer: RecordInfoRenderer) {
  defaultRecordInfoRenderer = renderer;
}

interface TreeListNode<T extends object> {
  id: any;
  level: number;
  entity: T;
  expanded: boolean;
  selected: boolean;
  children?: Array<TreeListNode<T>>;
  parent?: TreeListNode<T> | null;
}

interface TreeListInfo<T extends object> {
  nodes: Array<TreeListNode<T>>;
  nodeMap: Map<any, TreeListNode<T>>;
}

export interface DataListGroup<T extends object> {
  groupValue: any;
  items: T[];
}

interface ListRenderingInfo<T extends object> {
  data: T[] | null | undefined;
  groups?: Array<DataListGroup<T>>;
  treeList?: TreeListInfo<T> | null;
}

interface ListGroupHeaderProps {
  colSpan: number;
  groupValue: any;
  expanded: boolean;
  children?: ReactNode;
  onTriggerClick: (groupValue: any) => void;
}

interface ListGroupHeaderContentProps {
  groupValue: any;
  expanded: boolean;
  children: any;
  onTriggerClick: (groupValue: any) => void;
}

interface ListGroupHeaderActionsProps {
  groupValue: any;
  expanded: boolean;
  children: any;
}

class ListGroupHeaderContentWrapper extends PureComponent<
  Partial<ListGroupHeaderContentProps>
> {
  render() {
    return <ListGroupHeaderContent {...(this.props as any)} />;
  }
}

class ListGroupHeaderContent extends PureComponent<ListGroupHeaderContentProps> {
  render() {
    const { expanded, children } = this.props;
    return (
      <span
        className="m-datatable__group-header-content"
        onClick={this.onTriggerClick}
      >
        <a
          href="#"
          onClick={this.onTriggerClick}
          className="m-datatable__group-icon"
        >
          <i
            className={classNames({
              'la la-plus-square': !expanded,
              'la la-minus-square': expanded,
            })}
          />
        </a>
        {children}
      </span>
    );
  }

  onTriggerClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onTriggerClick(this.props.groupValue);
  };
}

class ListGroupHeaderActionsWrapper extends PureComponent<
  Partial<ListGroupHeaderActionsProps>
> {
  render() {
    return <ListGroupHeaderActions {...(this.props as any)} />;
  }
}

class ListGroupHeaderActions extends PureComponent<ListGroupHeaderActionsProps> {
  render() {
    const { children } = this.props;
    return (
      <span className="m-datatable__group-header-actions">{children}</span>
    );
  }
}

class ListGroupHeader extends PureComponent<ListGroupHeaderProps> {
  static Content = ListGroupHeaderContentWrapper;
  static Actions = ListGroupHeaderActionsWrapper;

  render() {
    const { colSpan, children, ...props } = this.props;
    const contents: any[] = React.Children.toArray(children);
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let header: any;
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let actions: any;
    for (const content of contents) {
      if (
        content &&
        (content.type === ListGroupHeaderContent ||
          content.type === ListGroupHeaderContentWrapper)
      ) {
        header = React.cloneElement(content, props);
      } else if (
        content &&
        (content.type === ListGroupHeaderActions ||
          content.type === ListGroupHeaderActionsWrapper)
      ) {
        actions = React.cloneElement(content, props);
      }
    }
    return (
      <tr className="m-datatable__group-header m-datatable__row">
        <td colSpan={colSpan} className="m-datatable__group-header-cell">
          <span className="m-datatable__group-header-wrapper">
            {header}
            {actions}
          </span>
        </td>
      </tr>
    );
  }
}

interface ItemDetailTriggerProps<T extends object> {
  expanded: boolean;
  width?: number;
  item: T;
  onClick: (item: T) => void;
}

class ItemDetailTrigger<T extends object> extends PureComponent<
  ItemDetailTriggerProps<T>
> {
  render() {
    const { width, expanded } = this.props;
    return (
      <span style={{ width }}>
        <a href="#" onClick={this.onClick}>
          <i className={`fa fa-angle-${expanded ? 'up' : 'right'}`} />
        </a>
      </span>
    );
  }

  onClick = (e: MouseEvent) => {
    e.preventDefault();
    this.props.onClick(this.props.item);
  };
}

interface ListItemCheckProps<T extends object> {
  item?: T;
  width?: number;
  checked: boolean;
  disabled?: boolean;
  onChange?: (
    item: T | undefined,
    checked: boolean,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  onClick?: (e: MouseEvent) => void;
}

class ListItemCheck<T extends object> extends PureComponent<
  ListItemCheckProps<T>
> {
  render() {
    const { width, checked, disabled, onClick } = this.props;
    return (
      <span style={{ width }}>
        <label
          className="m-checkbox m-checkbox--single m-checkbox--all m-checkbox--solid m-checkbox--brand"
          onClick={onClick}
        >
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={this.onChange}
          />
          &nbsp;
          <span />
        </label>
      </span>
    );
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    this.props.onChange && this.props.onChange(this.props.item, checked, e);
  };
}

export class DataTable<
  T extends object,
  TKey extends DataKeyType = number,
> extends Component<Props<T, TKey>> {
  static defaultProps: Partial<Props<any>> = {
    minHeight: 300,
    checkColWidth: 30,
    selModel: 'check',
    maxPagerItems: 5,
    renderRecordInfo: defaultRecordInfoRenderer,
  };

  private readonly blockRef = React.createRef<HTMLDivElement>();
  private readonly tableRef = React.createRef<HTMLTableElement>();
  private readonly highlighterContainer = React.createRef<HTMLDivElement>();

  componentDidMount() {
    this.positionHighlighters();
  }

  componentDidUpdate() {
    this.positionHighlighters();
  }

  render() {
    const { data, isLoading, error, total, minHeight } = this.props;
    const showPager = total && total > 0;
    return (
      <Block
        blockRef={this.blockRef}
        active={isLoading}
        style={{
          marginBottom: showPager ? '-2.2rem' : void 0,
          minHeight,
          overflowX: 'auto',
        }}
      >
        <div
          className={classNames(
            'm_datatable m-datatable m-datatable--default m-datatable--loaded',
            this.props.className,
          )}
        >
          {error && this.renderError()}
          {data && this.renderTable()}
        </div>
        {this.renderHighlighters()}
      </Block>
    );
  }

  renderError() {
    return <Alert color="danger">{this.props.error!.message}</Alert>;
  }

  renderEmpty() {
    let emptyPlaceholder = this.props.emptyPlaceholder;
    if (typeof emptyPlaceholder === 'function') {
      const extra = this.props.onGetExtraInfo?.();
      emptyPlaceholder = emptyPlaceholder(extra);
    }
    return (
      <div
        className="m-datatable__empty"
        style={{
          minHeight: this.props.minHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {this.props.isLoading
          ? null
          : emptyPlaceholder || <Translate id="data_table.no_data" />}
      </div>
    );
  }

  renderCheck(
    item: T | undefined,
    width: number | undefined,
    checked: boolean,
    onChange: (
      item: T,
      checked: boolean,
      e: ChangeEvent<HTMLInputElement>,
    ) => void,
    disabled?: boolean,
  ) {
    return (
      <ListItemCheck
        item={item}
        width={width}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        onClick={this.onCheckboxClick}
      />
    );
  }

  renderItemDetailTrigger(
    item: T,
    width: number | undefined,
    expanded: boolean,
  ) {
    return (
      <ItemDetailTrigger<T>
        item={item}
        width={width}
        expanded={expanded}
        onClick={this.onItemDetailTriggerClick}
      />
    );
  }

  getDataRowElPosition(elRow: HTMLElement) {
    let el = elRow;
    const pos = { left: 0, top: 0 };
    while (el && el !== this.blockRef.current) {
      pos.top += el.offsetTop;
      el = el.offsetParent as HTMLElement;
    }
    return pos;
  }

  renderHighlighters() {
    const { itemsBeingHighlighted, idProp } = this.props;
    if (!itemsBeingHighlighted?.length) return null;
    return (
      <div
        ref={this.highlighterContainer}
        style={{
          position: 'absolute',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {itemsBeingHighlighted.map(item => (
          <div
            key={item[idProp] as any}
            data-item-highlighter={item[idProp]}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              border: '2px solid #dfe0ef',
              backgroundColor: 'rgba(128, 128, 128, 0.05)',
            }}
          />
        ))}
      </div>
    );
  }

  positionHighlighters() {
    const { current: container } = this.highlighterContainer;
    if (!container) return;
    const highlighters: HTMLElement[] = Array.from(
      container.querySelectorAll('[data-item-highlighter]'),
    );
    for (const el of highlighters) {
      const id = el.getAttribute('data-item-highlighter');
      const elRow =
        this.tableRef.current &&
        (this.tableRef.current.querySelector(
          `[data-row="${id}"]`,
        ) as HTMLElement);
      if (elRow) {
        const { top } = this.getDataRowElPosition(elRow);
        el.style.top = top + 'px';
        el.style.height = elRow.offsetHeight + 'px';
      }
    }
  }

  renderInlineItemEditingRow(item: Partial<T> | T, index: number) {
    const { data, columns, selModel, checkColWidth, idProp, onGetExtraInfo } =
      this.props;
    const extra = onGetExtraInfo?.();

    return (
      <tr
        className="m-datatable__row"
        key={(item as T)[idProp] as any}
        data-row={(item as T)[idProp]}
      >
        {selModel === 'check' && (
          <td className="m-datatable__cell--center m-datatable__cell m-datatable__cell--check">
            {this.renderCheck(
              item as any,
              checkColWidth,
              false,
              noop,
              true /* disabled */,
            )}
          </td>
        )}
        {columns
          .filter(x => !this.isColHidden(x))
          .map((col, k) => (
            <td
              key={col.name || col.prop || k}
              className={classNames('m-datatable__cell', {
                [`m-datatable__cell--${col.align}`]: col.align,
              })}
            >
              <span style={{ width: col.width, overflow: 'visible' }}>
                {col.editor
                  ? col.editor(item, extra, index, data!)
                  : col.render
                    ? col.render(item as T, extra, 0, data!)
                    : (item as any)[col.prop]}
              </span>
            </td>
          ))}
      </tr>
    );
  }

  renderRows(
    group: DataListGroup<T> | null,
    data: T[],
    renderingInfo: ListRenderingInfo<T>,
  ) {
    const {
      idProp,
      selection,
      enableItemDetails,
      onRenderItemDetail,
      columns,
      selModel,
      checkColWidth,
      itemBeingUpdated,
      cellCls,
      rowClickable,
      onGetExtraInfo,
    } = this.props;

    const isGroupExpanded = group
      ? this.isListGroupExpanded(group.groupValue)
      : true;
    const totalColCount = this.getTotalColumnCount();
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let emptyCellPlaceholder: ReactNode;
    if (typeof this.props.emptyCellPlaceholder === 'function') {
      emptyCellPlaceholder = this.props.emptyCellPlaceholder(extra);
    } else {
      emptyCellPlaceholder = this.props.emptyCellPlaceholder;
    }

    return data!.map((item, i) => {
      if (itemBeingUpdated && item[idProp] === itemBeingUpdated[idProp]) {
        return this.renderInlineItemEditingRow(itemBeingUpdated, i);
      }
      const id = item[idProp];
      const dataRow = (
        <tr
          key={id as any}
          style={{ left: 0 }}
          data-row={id}
          className={classNames('m-datatable__row', {
            'm-datatable__row--even': i % 2 === 1,
            'm-datatable__row--clickable': rowClickable,
            'm-datatable__row--hidden':
              !isGroupExpanded ||
              (() => {
                if (!renderingInfo.treeList) return undefined;
                const treeListNode = renderingInfo.treeList.nodeMap.get(id);
                if (!treeListNode?.parent) {
                  return undefined;
                }
                let node: TreeListNode<T> | null | undefined =
                  treeListNode.parent;
                while (node && node.expanded) node = node.parent;
                return node ? true : false;
              })(),
          })}
          onClick={this.onRowClick.bind(this, item)}
        >
          {selModel === 'check' && (
            <td className="m-datatable__cell--center m-datatable__cell m-datatable__cell--check">
              {this.renderCheck(
                item,
                checkColWidth,
                selection ? selection.includes(item[idProp] as any) : false,
                this.onItemCheckChange,
              )}
            </td>
          )}
          {enableItemDetails && (
            <td className="m-datatable__cell-center m-datatable__cell m-datatable__cell-detail-trigger">
              {this.renderItemDetailTrigger(
                item,
                16,
                this.isItemDetailExpanded(item),
              )}
            </td>
          )}
          {columns
            .filter(x => !this.isColHidden(x))
            .map((col, k) => (
              <td
                key={col.name || col.prop || k}
                data-field={col.prop}
                className={classNames('m-datatable__cell', cellCls, col.cls, {
                  [`m-datatable__cell--${col.align}`]: col.align,
                })}
              >
                <span
                  style={Object.assign({}, col.style, {
                    width: col.style?.width ?? col.width,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent:
                      col.align === 'center'
                        ? 'center'
                        : col.align === 'right'
                          ? 'flex-end'
                          : 'flex-start',
                    flexWrap: 'nowrap',
                    paddingLeft: (() => {
                      if (k > 0 || !renderingInfo.treeList) return undefined;
                      const treeListNode =
                        renderingInfo.treeList.nodeMap.get(id);
                      if (!treeListNode) return undefined;
                      return treeListNode.level * (16 + 6);
                    })(),
                  })}
                >
                  {(() => {
                    if (k > 0 || !renderingInfo.treeList) return undefined;
                    const treeListNode = renderingInfo.treeList.nodeMap.get(id);
                    if (!treeListNode?.children?.length) {
                      return undefined;
                    }
                    const handler = treeListNode.expanded
                      ? this.onListItemNodeCollase(item)
                      : this.onListItemNodeExpand(item);
                    const icon = treeListNode.expanded
                      ? 'fa fa-minus-square'
                      : 'fa fa-plus-square';
                    return (
                      <a
                        href="#"
                        onClick={handler}
                        style={{ marginLeft: 1, marginRight: 5 }}
                      >
                        <i className={icon} />
                      </a>
                    );
                  })()}
                  {col.render
                    ? col.render(item, extra, i, data!)
                    : (() => {
                        const value = (item as any)[col.prop];
                        if (
                          value === null ||
                          value === undefined ||
                          value === '' ||
                          (typeof value === 'number' && isNaN(value))
                        ) {
                          let placeholder = emptyCellPlaceholder;
                          if (typeof col.emptyPlaceholder === 'function') {
                            placeholder = col.emptyPlaceholder(extra);
                          } else if (col.emptyPlaceholder) {
                            placeholder = col.emptyPlaceholder;
                          }
                          return placeholder;
                        }
                        return value;
                      })()}
                </span>
              </td>
            ))}
        </tr>
      );
      let detailRow: ReactNode | undefined = undefined;
      const expanded = this.isItemDetailExpanded(item);
      if (enableItemDetails) {
        const itemDetail = onRenderItemDetail ? onRenderItemDetail(item) : null;
        detailRow =
          (expanded && itemDetail && (
            <tr
              className={classNames({
                'm-datatable__row': true,
                'm-datatable__row--hidden': !isGroupExpanded || !expanded,
              })}
            >
              <td colSpan={totalColCount}>{itemDetail}</td>
            </tr>
          )) ||
          null;
      }
      return (
        <React.Fragment key={id as any}>
          {dataRow}
          {detailRow}
        </React.Fragment>
      );
    });
  }

  renderTable() {
    const {
      selection,
      offset,
      limit,
      total,
      maxPagerItems,
      enableItemDetails,
      columns,
      selModel,
      checkColWidth,
      minHeight,
      itemBeingCreated,
      groupListKey,
      onFormatGroupHeader,
      onRenderListGroupActions,
      renderRecordInfo,
      tableStyle,
    } = this.props;

    const renderingInfo = this.buildListRenderingInfo();

    const { data, groups } = renderingInfo;

    const isAllSelected = Boolean(
      selection?.length &&
        (groups
          ? groups.reduce((c, { items }) => {
              c += items.length;
              return c;
            }, 0) === selection.length
          : data && selection.length === data.length),
    );

    const showPager =
      typeof total === 'number' &&
      total > 0 &&
      typeof offset === 'number' &&
      offset >= 0 &&
      typeof limit === 'number' &&
      limit > 0;

    const isEmpty = Boolean(
      (groups ? !groups.length : !data?.length) && !itemBeingCreated,
    );

    return (
      <>
        <table
          className="m-datatable__table"
          style={Object.assign(
            {
              display: 'table',
              overflowX: 'auto',
              minHeight,
              minWidth: '100%',
            },
            tableStyle,
          )}
          ref={this.tableRef}
        >
          {/* render data table header */}
          <thead className="m-datatable__head">
            <tr className={classNames('m-datatable__row')}>
              {selModel === 'check' && (
                <th className="m-datatable__cell--center m-datatable__cell m-datatable__cell--check">
                  {this.renderCheck(
                    undefined,
                    checkColWidth,
                    isAllSelected,
                    this.onToggleAllSelection,
                  )}
                </th>
              )}
              {enableItemDetails && (
                <th className="m-datatable__cell--center m-datatable__cell m-datatable__cell--detail-trigger">
                  <span style={{ width: 16 }}>
                    <span
                      className="la la-expand"
                      style={{ verticalAlign: 'middle' }}
                    />
                  </span>
                </th>
              )}
              {columns
                .filter(x => !this.isColHidden(x))
                .map((col, i) => (
                  <th
                    key={i}
                    data-field={col.prop}
                    onClick={this.onColumHeaderClick(col)}
                    className={classNames('m-datatable__cell', {
                      'm-datatable__cell--sort': col.sortable,
                      [`m-datatable__cell--${col.align}`]: col.align,
                    })}
                  >
                    <span
                      style={{
                        width: col.style?.width ?? col.width,
                        minWidth: col.style?.minWidth,
                      }}
                    >
                      {typeof col.text === 'function' ? col.text() : col.text}
                      {(() => {
                        const dir = this.getColumnSortDir(col);
                        if (!dir) return null;
                        if (dir === 'asc') {
                          return <i className="la la-arrow-up" />;
                        }
                        return <i className="la la-arrow-down" />;
                      })()}
                    </span>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="m-datatable__body">
            {itemBeingCreated &&
              this.renderInlineItemEditingRow(itemBeingCreated, 0)}
            {isEmpty ? (
              <tr className="m-datatable__row">
                <td>{this.renderEmpty()}</td>
              </tr>
            ) : groups ? (
              groups.map(group => {
                const headerContent = onFormatGroupHeader
                  ? onFormatGroupHeader(group, groupListKey)
                  : group.groupValue;
                const actions = onRenderListGroupActions
                  ? onRenderListGroupActions(group, groupListKey)
                  : null;
                const totalColCount = this.getTotalColumnCount();
                const expanded = this.isListGroupExpanded(group.groupValue);
                const header = (
                  <ListGroupHeader
                    colSpan={totalColCount}
                    expanded={expanded}
                    groupValue={group.groupValue}
                    onTriggerClick={this.onListGroupTriggerClick}
                  >
                    <ListGroupHeader.Content>
                      {headerContent}
                    </ListGroupHeader.Content>
                    {actions && (
                      <ListGroupHeader.Actions>
                        {actions}
                      </ListGroupHeader.Actions>
                    )}
                  </ListGroupHeader>
                );
                const { groupValue } = group;
                const key =
                  groupValue === null ||
                  groupValue === undefined ||
                  (typeof groupValue === 'number' && isNaN(groupValue)) ||
                  groupValue === ''
                    ? '__missing_group_value__'
                    : groupValue;
                return (
                  <React.Fragment key={key}>
                    {header}
                    {this.renderRows(group, group.items, renderingInfo)}
                  </React.Fragment>
                );
              })
            ) : (
              this.renderRows(null, data || [], renderingInfo)
            )}
          </tbody>
        </table>
        {showPager && (
          <Pager
            offset={offset!}
            limit={limit!}
            total={total!}
            maxPagerItems={maxPagerItems!}
            onOffsetChange={this.onOffsetChange}
            onLimitChange={this.onLimitChange}
            style={{ paddingBottom: 20 }}
            renderRecordInfo={renderRecordInfo}
          />
        )}
      </>
    );
  }

  getNodeId(node: EntityNode<T>): any {
    return node.entity[this.props.idProp];
  }

  buildListRenderingInfo(): ListRenderingInfo<T> {
    if (!this.props.data) return { data: this.props.data };
    if (!this.props.treeBuilder) {
      if (!this.props.groupBy) return { data: this.props.data };
      // group the data
      const groups: Array<DataListGroup<T>> = [];
      const groupMap = new Map<any, DataListGroup<T>>();
      for (const item of this.props.data) {
        const groupValue =
          typeof this.props.groupBy === 'function'
            ? this.props.groupBy(item)
            : item[this.props.groupBy];
        if (!groupMap.has(groupValue)) {
          const group: DataListGroup<T> = { groupValue, items: [] };
          groupMap.set(groupValue, group);
          groups.push(group);
        }
        groupMap.get(groupValue)!.items.push(item);
      }
      return { data: undefined, groups };
    }

    const data: T[] = [];
    const extra = this.props.onGetExtraInfo?.();
    const nodes = this.props.treeBuilder(this.props.data, extra);

    const treeList: TreeListInfo<T> = {
      nodes: [],
      nodeMap: new Map<any, TreeListNode<T>>(),
    };

    const traverse = (
      node: EntityNode<T>,
      parentTreeListNode: TreeListNode<T> | null | undefined,
      level: number,
    ) => {
      const id = this.getNodeId(node);

      const treeListNode: TreeListNode<T> = {
        id,
        level,
        entity: node.entity,
        expanded: node.expanded,
        selected: node.selected,
        parent: parentTreeListNode,
      };

      if (parentTreeListNode) {
        if (!parentTreeListNode.children) {
          parentTreeListNode.children = [];
        }
        parentTreeListNode.children.push(treeListNode);
      } else {
        treeList.nodes.push(treeListNode);
      }

      treeList.nodeMap.set(id, treeListNode);

      data.push(node.entity);

      if (node.children?.length) {
        for (const childNode of node.children) {
          traverse(childNode, treeListNode, level + 1);
        }
      }
    };

    for (const node of nodes) {
      traverse(node, null, 0);
    }

    return { data, treeList };
  }

  onOffsetChange = (offset: number) => {
    this.props.onOffsetChange && this.props.onOffsetChange(offset);
  };

  onLimitChange = (limit: number) => {
    this.props.onLimitChange && this.props.onLimitChange(limit);
  };

  onToggleAllSelection = () => {
    this.props.onToggleAllSelection && this.props.onToggleAllSelection();
  };

  onCheckboxClick = (e: MouseEvent<HTMLLabelElement>) => {
    if (this.props.rowClickable) {
      e.stopPropagation();
    }
  };

  onRowClick = (item: T) => {
    if (this.props.rowClickable) {
      const extra = this.props.onGetExtraInfo?.();
      this.props.onRowClick && this.props.onRowClick(item, extra);
    }
  };

  onItemCheckChange = (item: T, checked: boolean) => {
    this.props.onItemSelect && this.props.onItemSelect(item, checked);
  };

  onItemDetailTriggerClick = (item: T) => {
    if (this.isItemDetailExpanded(item)) {
      this.props.onItemDetailCollapse && this.props.onItemDetailCollapse(item);
    } else {
      this.props.onItemDetailExpand && this.props.onItemDetailExpand(item);
    }
  };

  onListGroupTriggerClick = (value: any) => {
    const { groupListKey } = this.props;
    if (this.isListGroupExpanded(value)) {
      this.props.onListGroupCollapse &&
        this.props.onListGroupCollapse(value, groupListKey);
    } else {
      this.props.onListGroupExpand &&
        this.props.onListGroupExpand(value, groupListKey);
    }
  };

  onListItemNodeExpand = (item: T) => {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      this.props.onListItemNodeExpand && this.props.onListItemNodeExpand(item);
    };
  };

  onListItemNodeCollase = (item: T) => {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      this.props.onListItemNodeCollapse &&
        this.props.onListItemNodeCollapse(item);
    };
  };

  onColumHeaderClick(col: Column<T>) {
    return (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (!col.sortable) return;
      const property = col.sortProperty || col.prop;
      const { onToggleSort } = this.props;
      onToggleSort && onToggleSort(property, col.defaultSortDir);
    };
  }

  isColHidden(col: Column<T>): boolean {
    if (typeof col.hidden === 'function') {
      return col.hidden();
    }
    return Boolean(col.hidden);
  }

  getColumnSortDir(col: Column<T>): SortDirection | null {
    const { sorts } = this.props;
    if (!sorts?.length) return null;
    const property = col.sortProperty || col.prop;
    const sort = sorts.find(x => x.property === property);
    return sort ? sort.dir : null;
  }

  isItemDetailExpanded(item: T): boolean {
    const {
      idProp,
      enableItemDetails,
      expandItemDetailByDefault,
      expandedItemDetailIds,
      collapsedItemDetailIds,
    } = this.props;
    const id = item[idProp];
    return Boolean(
      enableItemDetails &&
        ((expandItemDetailByDefault && !collapsedItemDetailIds?.has(id)) ||
          (!expandItemDetailByDefault && expandedItemDetailIds?.has(id))),
    );
  }

  isListGroupExpanded(value: any): boolean {
    const { groupBy, collapseGroupByDefault, expandedGroups, collapsedGroups } =
      this.props;
    return Boolean(
      groupBy &&
        ((collapseGroupByDefault && expandedGroups?.has(value)) ||
          (!collapseGroupByDefault && !collapsedGroups?.has(value))),
    );
  }

  getTotalColumnCount(): number {
    const { columns, selModel, enableItemDetails } = this.props;
    let colCount = columns.length;
    if (selModel === 'check') {
      colCount++;
    }
    if (enableItemDetails) {
      colCount++;
    }
    return colCount;
  }
}
