import classNames from 'classnames';
import { ColorType } from 'model';
import React, { CSSProperties, HTMLProps, ReactNode } from 'react';

interface Props extends Omit<HTMLProps<HTMLElement>, 'value'> {
  value?: boolean;
  trueColor?: ColorType;
  falseColor?: ColorType;
  showFalseIcon?: boolean;
  falsePlaceholder?: ReactNode;
  checkedIconCls?: string;
  uncheckedIconCls?: string;
  style?: CSSProperties;
  className?: string;
}

// import CheckIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/navigation/check.svg';

export const Checkmark = React.memo<Props>(
  ({
    value,
    checkedIconCls = 'fa fa-check-circle',
    uncheckedIconCls = 'fa fa-minus-circle',
    trueColor = 'success',
    falseColor = 'danger',
    showFalseIcon = false,
    falsePlaceholder = '-',
    ...props
  }) => (
    <>
      {value ? (
        <i
          className={classNames(checkedIconCls, {
            [`m--font-${trueColor}`]: trueColor,
          })}
          {...props}
        />
      ) : showFalseIcon ? (
        <i
          className={classNames(uncheckedIconCls, {
            [`m--font-${falseColor}`]: falseColor,
          })}
          {...props}
        />
      ) : (
        falsePlaceholder
      )}
    </>
  ),
);
