import { ServiceFeatureName, ServiceFeatureNameValueSet } from 'model';
import { CSSProperties, Fragment, memo, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { Checkbox } from 'shared/components/Checkbox';

export const FeatureTable = memo(
  ({
    editable,
    numCols = 5,
    isFeatureEnabled,
    isIndependentDeliveryCheckEnabled,
    style,
    className,
    onToggleFeature,
    onToggleIndependentDeliveryCheck,
  }: {
    editable?: boolean;
    numCols?: number;
    style?: CSSProperties;
    className?: string;
    isFeatureEnabled: (feature: ServiceFeatureName) => boolean;
    isIndependentDeliveryCheckEnabled?: boolean;
    onToggleFeature?: (feature: ServiceFeatureName, enabled: boolean) => void;
    onToggleIndependentDeliveryCheck?: (enabled: boolean) => void;
  }) => {
    type FeatureInfo = {
      name: ServiceFeatureName | 'isIndependentDeliveryCheckEnabled' | '';
      label: string;
      enabled: boolean;
    };
    type Row = FeatureInfo[];

    const rows = useMemo(() => {
      const features: FeatureInfo[] = [];
      for (const name of ServiceFeatureNameValueSet) {
        features.push({
          name,
          label: `service_feature_name.${name}`,
          enabled: isFeatureEnabled(name),
        });
      }
      if (isIndependentDeliveryCheckEnabled != null) {
        features.push({
          name: 'isIndependentDeliveryCheckEnabled',
          label: 'store.service_info.label.independent_delivery_check',
          enabled: isIndependentDeliveryCheckEnabled,
        });
      }

      const rowList: Row[] = [];

      let lastRow: FeatureInfo[] = [];
      for (let i = 0; i < features.length; i++) {
        if (i % numCols === 0) {
          lastRow = [];
          rowList.push(lastRow);
        }
        lastRow.push(features[i]);
      }
      if (lastRow.length < numCols) {
        for (let i = lastRow.length; i < numCols; i++) {
          lastRow.push({ name: '', label: '', enabled: false });
        }
      }

      return rowList;
    }, [isFeatureEnabled, isIndependentDeliveryCheckEnabled, numCols]);

    return (
      <table
        style={{
          borderCollapse: 'collapse',
          borderSpacing: 0,
          width: '100%',
          ...style,
        }}
        className={className}
      >
        {rows.map((row, i) => (
          <Fragment key={i}>
            <tr>
              {row.map((x, j) => (
                <td
                  key={j}
                  style={{
                    whiteSpace: 'nowrap',
                    border: '1px solid #ddd',
                    backgroundColor: '#eee',
                    textAlign: 'center',
                    padding: '0 0.15rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  <span
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 400,
                      lineHeight: '1.7rem',
                    }}
                  >
                    {x.name ? <Translate id={x.label} /> : ''}
                  </span>
                </td>
              ))}
            </tr>
            <tr>
              {row.map((x, j) => (
                <td
                  key={j}
                  style={{
                    whiteSpace: 'nowrap',
                    border: '1px solid #ddd',
                    textAlign: 'center',
                    height: editable ? 24 : 12,
                    lineHeight: '1.2rem',
                  }}
                >
                  {x.name ? (
                    editable ? (
                      <Checkbox
                        checked={x.enabled}
                        style={{ marginBottom: 0, verticalAlign: 'middle' }}
                        onChange={() => {
                          if (x.name === 'isIndependentDeliveryCheckEnabled') {
                            onToggleIndependentDeliveryCheck?.(!x.enabled);
                          } else {
                            onToggleFeature?.(
                              x.name as ServiceFeatureName,
                              !x.enabled,
                            );
                          }
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          fontSize: '0.7rem',
                          color: x.enabled ? '#34bfa3' : '#f4516c',
                        }}
                      >
                        {x.enabled ? '✔' : '✘'}
                      </span>
                    )
                  ) : (
                    ''
                  )}
                </td>
              ))}
            </tr>
          </Fragment>
        ))}
      </table>
    );
  },
);
